import React from 'react'
import tw from 'twin.macro'

import Layout from '../components/Layout'
import SEO from '../components/Seo'
import { graphql } from 'gatsby'
import Container from '../components/Container'
import CategoryNav from '../components/Post/CategoryNav';
import PostContentGroup from '../components/Post/ContentGroup';


const CategoryTemplate = ({ data }) => {

    const { categorySlug, } = data.currentCategory.edges[0].node;
    const posts = data.currentCategory.edges[0].node.posts.nodes;
    
    return (
        <Layout>
            <SEO />
            <Container 
                tw="grid mt-10 md:(grid-cols-3) lg:(grid-cols-4) xl:(grid-cols-5) gap-8"
            >
                {/* Sticky Categories Nav */}
                <div tw="h-full relative">
                    <CategoryNav currentCategory={categorySlug} />
                </div>

                {/* POSTS SECTION */}
                <div tw="md:(col-span-2) lg:(col-span-3) xl:(col-span-4)">
                    <PostContentGroup posts={posts}/>
                </div>

            </Container>
        </Layout>
    )
}

export const query = graphql`
    query($id: String!) {
        # Get posts from the category
        currentCategory : allWpCategory (
            filter: { id: {eq: $id} }
            sort: {
                fields: wpChildren___nodes___posts___nodes 
                order: DESC
            }
        ) {
            edges {
                node {
                    categoryId: id
                    categoryName: name
                    categorySlug: slug
                    posts  {
                        nodes {
                            date
                            excerpt
                            title
                            slug
                            featuredImage {
                                node {
                                    localFile {
                                        childImageSharp {
                                            fluid(maxHeight: 800) {
                                                ...GatsbyImageSharpFluid_withWebp
                                            }
                                        }
                                    }
                                }
                            }
                            categories {
                                nodes {
                                    id
                                    name
                                    slug
                                }
                            }
                        }
                    }
                }
            }
        }

        # get all the categories for the category nav
        allWpCategory {
            edges {
                node {
                    name
                    slug
                }
            }
        }
    }
`

export default CategoryTemplate